<template>
<div class="dashboard-container">
	<v-app>
		<!-- Header -->
		<div class="header-container">
			<div class="flex-grow-0	mr-1 p-0">
				<CButton class="mr-1" @click="goBack()" color="danger" size="sm">
					Kembali
				</CButton>
			</div>
			<!-- Search Box -->
			<div class="flex-grow-1 mr-1">
				<document-search-box :type-prop="'Semua'"/>
			</div>
			<!-- Create Document Action -->
			<div class="flex-grow-0	p-0">
				<CButton class="mr-1" @click="showSubtakahForm" color="danger" size="sm">
					<CIcon name="cil-plus"/> Tambah Subtakah
				</CButton>
				<CButton @click="showTakahForm" color="danger" size="sm">
					<CIcon name="cil-plus"/> Tambah Baru
				</CButton>
			</div>
		</div>
		<div>
			<advance-search
				ref="advanceSearch"
				:category-options-prop="document.categoryOptions"
				@apply-advance-filter="applyAdvanceFilter"/>
		</div>
		<hr class="bottom-devider"/>
		<!-- Body -->
		<div class="body-container">
			<CListGroup class="mb-10 mt-10">
				<div v-if="renderSubtakah === true" class="loader content-center p-2">
					<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
				</div>
				<CListGroupItem href="#" v-for="(item, index) in subtakah" :key="index" class="flex-column mb-10 p-0" style="border-width:0">
					<div class="d-flex w-100">
						<div class="flex-grow-1">
							<CCard class="p-0 mb-0" @click="toDocList(item)">
								<CCardBody>
									<div class="d-flex w-100">
										<div class="flex-grow-0 mr-10">
											<div class="media-img-wrap">
												<font-awesome-icon icon="folder" size="2x" class="text-folder"/>
											</div>
										</div>
										<div class="flex-grow-1 text-ellipsis">
											<div class="d-flex w-100">
												<div class="mb-1 flex-grow-1">
													<div class="font-weight-bold">{{ combineNoAndName(item) }}</div>
													<div lass="help-block small">{{ item.description }}</div>
												</div>
												<div class="flex-grow-0 text-right">
													<div>
														<CBadge color="danger" shape="pill">{{ item.documentCount }}</CBadge>
													</div>
													<div class="help-block small">{{ item.createdAt }}</div>
												</div>
											</div>
										</div>
									</div>
								</CCardBody>
							</CCard>
						</div>
						<div class="flex-grow-0">
							<CDropdown class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
								<template #toggler>
									<div class="p-3 mt-3">
										<font-awesome-icon icon="cog" class="clickable text-grey"/>
									</div>
								</template>

								<CDropdownItem class="mt-2" @click="showSubtakahEdit(item)">
									<div class="d-flex w-100">
										<div class="flex-grow-1">
											<div class="small">
												<b>Ubah</b>
											</div>
										</div>
									</div>
								</CDropdownItem>

								<CDropdownItem class="mt-2" @click="deleteSubtakah(item)">
									<div class="d-flex w-100">
										<div class="flex-grow-1">
											<div class="small">
												<b>Hapus</b>
											</div>
										</div>
									</div>
								</CDropdownItem>
							</CDropdown>
						</div>
					</div>
				</CListGroupItem>
			</CListGroup>
			<div class="p-2 position-relative mb-10" style="min-height:150px">
				<div v-if="renderData === true" class="loader content-center p-2">
					<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
				</div>
				<div class="p-5" v-else-if="document.data.length == 0 && renderData === false">
					<p class="text-center">Maaf, Surat Tidak Ditemukan.</p>
				</div>
				<!-- Document Card -->
				<CRow v-else>
					<CCol v-for="(item, index) in document.data" :key="index" col="4">
						<document-card
							v-bind:item-prop="item"
							v-bind:is-takah-prop="true"
							v-on:show-disposition-form="showDispositionForm"
							v-on:success-remove-takah="resetList">
						</document-card>
					</CCol>
				</CRow>
			</div>
			<div class="content-center">
				<CPagination :activePage.sync="pagination.activePage" :pages="pagination.pages" align="center"/>
			</div>
		</div>

		<!-- Popup Modal & Input Form -->
		<add-form
			:popup-modal-prop="takahForm.popupModal"
			:takah-id-prop="parseInt(this.$route.params.id)"
			@close-popup-modal="closeTakahForm">
		</add-form>

		<!-- Disposition Popup Modal & Input Form -->
		<disposition-form
			:popup-modal-prop="pDispositionForm.popupModal"
			:doc-id-prop="pDispositionForm.input.docId"
			:memo-record-id-prop="pDispositionForm.input.memoRecordId"
			:user-options-prop="pDispositionForm.receiverOptions"
			@close-popup-modal="closeDispositionForm">
		</disposition-form>
	</v-app>
	<!-- Popup Modal & Input Form -->
	<CModal :show.sync="subTakahForm.popupModal.isShow">
		<template #header>
			<h5 class="modal-title">{{ subTakahForm.popupModal.modalTitle }}</h5>
		</template>
		<!-- Input Form -->
		<div class="p-1">
			<CInput
				v-model="subTakahForm.input.no"
				label="Nomor Takah"
				placeholder="Nomor Takah"
				:is-valid="subTakahForm.validate.no"
				@change="validateForm('no')" />
			<CInput
				v-model="subTakahForm.input.name"
				label="Nama Subtakah"
				placeholder="Nama Subtakah"
				:is-valid="subTakahForm.validate.name"
				@change="validateForm('name')" />
			<CTextarea
				v-model="subTakahForm.input.desc"
				label="Deskripsi Subtakah"
				placeholder="Deskripsi Subtakah"
				:is-valid="subTakahForm.validate.desc"
				rows ="3" 
				@change="validateForm('desc')" />
			<CInput
				v-model="subTakahForm.input.pid"
				type="hidden"/>
		</div>
		<template #footer>
			<CButton @click="closeSubtakahForm" class="btn-sm">Batal</CButton>
			<CButton v-if="subTakahForm.editedIndex != -1" @click="updateSubtakah" color="success" class="btn-sm" :disabled="subTakahForm.submitBtn.disabled">
				<font-awesome-icon v-if="subTakahForm.submitBtn.loader" icon="spinner" spin/> {{ subTakahForm.submitBtn.text }}
			</CButton>
			<CButton v-else @click="saveSubtakah" color="success" class="btn-sm" :disabled="subTakahForm.submitBtn.disabled">
				<font-awesome-icon v-if="subTakahForm.submitBtn.loader" icon="spinner" spin/> {{ subTakahForm.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import AdvanceSearch from '@/components/document/AdvanceSearch.vue'
import DocumentCard from '@/components/document/DocumentCard.vue'
import DocumentSearchBox from '@/components/document/SearchBox.vue'
import DispositionForm from '@/components/document/DispositionFormNew.vue'
import AddFilesForm from './modal/AddForm.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
	names: 'Files',
	components: {
		'advance-search': AdvanceSearch,
		'document-card': DocumentCard,
		'document-search-box': DocumentSearchBox,
		'disposition-form': DispositionForm,
		'add-form': AddFilesForm
	},
	data () {
		const document = {
			data: [],
			filters: {
				title: "",
				docNo: "",
				dateStart: "",
				dateFinish: "",
				category: "",
				type: "Semua",
				trait: "Semua",
				badge: "Semua",
				level: "Semua",
				isPublish: false,
				isFavorite: false,
				isArchieve: true,
				orderBy: "created_at",
				orderType: "desc",
				takahId: null
			},
			sorts: null,
			categoryOptions: []
		}
		const pagination = {
			itemsPerPage: 9,
			activePage: 1,
			pages: 0
		}
		const pDispositionForm = {
			receiverOptions: [],
			popupModal: {
				isShow: false,
				modalTitle: 'Disposisi Surat'
			},
			input: {
				docId: 0,
				memoRecordId: null
			},
			defaultValue: {
				docId: 0,
				memoRecordId: null
			}
		}
		const takahForm = {
			takahId: null,
			popupModal: {
				isShow: false,
				modalTitle: 'Form Takah'
			}
		}
		const subTakahForm = {
			takahId: null,
			popupModal: {
				isShow: false,
				modalTitle: 'Tambah Subtakah'
			},
			//Input
			editedIndex: -1,
			input: {
				name: '',
				desc: '',
				pid: null,
				pid_name: '',
				no: ''
			},
			defaultValue: {
				name: '',
				desc: '',
				pid: null,
				pid_name: '',
				no: ''
			},
			validate: {
				name: null,
				desc: null,
				no: null
			},
			submitBtn: {
				loader: false,
				text: 'Simpan',
				disabled: true
			}
		}
		const history = {
			position: 0,
			pages: [],
			path: []
		}
		return {
			renderData: true,
			renderSubtakah: true,
			document,
			subtakah: [],
			pagination,
			pDispositionForm,
			takahForm,
			subTakahForm,
			history
		}
	},
	computed: {
		...mapState ('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		}),
		takahId () {
			return parseInt(this.$route.params.id)
		},
		activePage () {
			return this.pagination.activePage
		}
	},
	watch: {
		takahId (val) {
			this.takahForm.takahId = val
		},
		activePage (){
			this.renderData = true
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
				})
		}
	},
	mounted () {
		this.getDocumentList()
			.then( data => {
				this.document.data = data.items
				this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
				this.renderData = false
			})
		this.getSubtakahList()
			.then( data => {
				this.subtakah = data.subtakah
			})
		this.renderSubtakah = false
		this.makeHistory()
		this.getCategoryOptions()
		this.getReceiverOptions()
	},
	methods: {
		// Filter
		applyAdvanceFilter (params) {
			let advanceSearchComp = this.$refs.advanceSearch
			advanceSearchComp.loading = true
			if (this.takahId)
				params.filters.takahId = this.takahId
			this.document.filters = params.filters
			this.document.sorts = params.sorts
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
					advanceSearchComp.refreshFilterBadges()
				}).catch( err => {
					console.log('Failed fetch document data with filter')
					console.log(err)
				}).finally(() => {
					advanceSearchComp.loading = false
				})
		},
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
					}
					items.push(rowData)
				})
				
				this.document.categoryOptions = items
			})
		},
		getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 999,
						offset: 0
					},
					order: {
						orderBy: 'fullname',
						orderType: 'asc'
					},
					filter: {
						isActive: 'true'
					}
				}
			).then( res => {
				let items = []
				for (let i = 0; i < res.result.length; i++) {
					const item = res.result[i];
					let position = (item.position != null) ? item.position : ''
					let positionExisted = items.some(el => el.position === position)
					if ((item.position != this.loggedUserPosition) && !positionExisted) {
						let label = position.trim()
						let rowData = {
							label: label,
							id: item.user_id,
							userId: item.user_id,
							fullname: item.fullname,
							email: item.email,
							rank: item.rank,
							position: item.position,
							command: null
						}
						items.push(rowData)
					}
				}
				
				this.pDispositionForm.receiverOptions = items
			})
		},
		getDocumentList () {
			if (this.takahId)
				this.document.filters.takahId = this.takahId

			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocument',
						pagination: {
							limit: this.pagination.itemsPerPage,
							offset: (this.pagination.activePage-1)*this.pagination.itemsPerPage
						},
						filter: this.document.filters,
						sort: this.document.sorts
					}
				).then( res => {
					let items = []
					let total = (res.total_count)?res.total_count:res.result.length
					res.result.forEach((item) => {
						items.push(item)
					})
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
		resetList () {
			this.renderData = true
			this.document.data = []
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
				})
		},
		resetSubtakahList () {
			this.renderSubtakah = true
			this.subtakah = []
			this.getSubtakahList()
				.then( data => {
					this.subtakah = data.subtakah
				})
			this.renderSubtakah = false
		},
		getSubtakahList () {
			this.loader = true

			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'takah/loadData',
					filter: { access: "all", takah_pid: this.takahId },
					pagination: {
						limit: 100,
						offset: 0
					}
				}).then( res => {
					if (res && res.result.length > 0) {
						const data = res.result
						let subtakah = []
						data.forEach( item => {
							let subtakahRow = {
								takahId: item.takah_id,
								takah: item.takah,
								description: item.description,
								documentCount: item.document_count,
								createdAt: this.$moment(item.created_at).format("Y-MM-DD, HH:mm"),
								takahNo: item.takah_no
							}
							subtakah.push(subtakahRow)
							// this.subtakah.push(subtakahRow)
						})

						setTimeout(() => {
							resolve({
								subtakah
							})
						}, 1000)
					}
				}).catch( err => {
					console.log('Failed to fetch takah data')
					console.log(err)
				}).finally( () => {
					this.loader = false
				})
			})	
		},
		getSingleData (takahId) {
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'takah/loadData',
					filter: { takah_id: takahId },
				}).then( res => {
					if (res && res.result.length > 0) {
						const data = res.result[0]
						let single = {}
						
						single.takahId = data.takah_id,
						single.takah = data.takah,
						single.description = data.description,
						single.takahNo = data.takah_no

						setTimeout(() => {
							resolve({
								single
							})
						}, 1000)
					}
				}).catch( err => {
					console.log('Failed to fetch takah data')
					console.log(err)
				}).finally( () => {
					this.loader = false
				})
			})	
		}
		,

		// Disposition
		showDispositionForm (item) {
			if (item != null) {
				let memoRecordId = (item.disposition && item.disposition.memo_record_id) ? item.disposition.memo_record_id : null
				this.pDispositionForm.input.docId = item.doc_id
				this.pDispositionForm.input.memoRecordId = memoRecordId
				this.pDispositionForm.popupModal.isShow = true
			}
		},
		closeDispositionForm () {
			this.pDispositionForm.popupModal.isShow = false
			this.pDispositionForm.input = Object.assign({}, this.pDispositionForm.defaultValue)
		},

		// Takah
		showTakahForm () {
			if (this.takahForm.takahId == null) this.takahForm.takahId = this.takahId
			this.takahForm.popupModal.isShow = true
		},
		closeTakahForm (reloadState = false) {
			if (reloadState) this.resetList()
			this.takahForm.popupModal.isShow = false
		},

		//Takah baru
		showSubtakahForm () {	
			this.subTakahForm.input = this.subTakahForm.defaultValue
		
			if (this.subTakahForm.input.pid == null) this.subTakahForm.input.pid = this.takahId
			// if (this.subTakahForm.pid_name == '') this.subTakahForm.pid_name = this.takahId
			
			this.subTakahForm.validate.name = null
			this.subTakahForm.validate.desc = null
			this.subTakahForm.validate.no = null
			this.subTakahForm.popupModal.isShow = true
		},
		showSubtakahEdit (item) {
			this.subTakahForm.editedIndex = item.takahId
			this.subTakahForm.input = {
				takahId: item.takahId,
				name: item.takah,
				desc: item.description,
				takah_pid: item.takah_pid,
				no: item.takahNo
			}
			this.validateForm('name')
			this.validateForm('desc')
			this.validateForm('no')
			this.subTakahForm.popupModal.modalTitle = 'Ubah  ' + item.takah
			this.subTakahForm.popupModal.isShow = true
		},
		closeSubtakahForm (reloadState = false) {
			if (reloadState) this.resetList()
			this.subTakahForm.input.name = ''
			this.subTakahForm.input.desc = ''
			this.subTakahForm.input.pid = null
			this.subTakahForm.validate.name = null
			this.subTakahForm.validate.desc = null
			this.subTakahForm.popupModal.isShow = false
		},
		validateForm (field) {
			if (field == 'name') {
				if (this.subTakahForm.input.name != '') {
					this.subTakahForm.validate.name = true
				} else {
					this.subTakahForm.validate.name = false
					return false
				}
			}
			if (field == 'desc') {
				if (this.subTakahForm.input.desc != '') {
					this.subTakahForm.validate.desc = true
				} else {
					this.subTakahForm.validate.desc = false
					return false
				}
			}
			if (field == 'no') {
				if (this.subTakahForm.input.no != '') {
					this.subTakahForm.validate.no = true
				} else {
					this.subTakahForm.validate.no = false
					return false
				}
			}

			if (this.subTakahForm.validate.name &&
				this.subTakahForm.validate.desc)
				this.subTakahForm.submitBtn.disabled = false
		},
		saveSubtakah () {
			console.log(this.subTakahForm.input)
			this.subTakahForm.submitBtn = {
				loader: true,
				text: 'Memproses...',
				disabled: true
			}
			this.$store.dispatch({
				type: 'takah/store',
				formInput: this.subTakahForm.input
			}).then( res => {
				console.log("Result: ", res)
				this.subTakahForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
				this.resetList()
				this.resetSubtakahList()
				setTimeout( e => {
					this.$toasted.success('Sukses menambah data!').goAway(3000)
					this.closeSubtakahForm()
				}, 1500)
			}).catch( err => {
				console.log(err)
				this.subTakahForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
				this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
			})
		},
		updateSubtakah () {
			this.$confirm({
				auth: false,
				message: "Anda yakin ingin mengubah data ini?",
				button: {
					no: 'Tidak',
					yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.subTakahForm.submitBtn = {
							loader: true,
							text: 'Memproses...',
							disabled: true
						}
						
						this.$store.dispatch({
							type: 'takah/update',
							formInput: this.subTakahForm.input
						}).then( res => {
							console.log("Result: ", res)
							this.subTakahForm.submitBtn = {
								loader: false,
								text: 'Simpan',
								disabled: true
							}
							this.resetSubtakahList()
							this.resetList()
							setTimeout( e => {
								this.$toasted.success('Sukses mengubah data!').goAway(3000)
								this.closePopupModal()
							}, 1500)
						}).catch( err => {
							console.log("Error: ", err.response)
							this.subTakahForm.submitBtn = {
								loader: false,
								text: 'Simpan',
								disabled: true
							}
							let errorMsg = "Gagal menghapus data!"
							if (err.response.status == 401) errorMsg = "Akses terbatas untuk aksi yang dipilih!"
							this.$toasted.error(errorMsg).goAway(3000)
						})
					}
				}
			})
		},
		deleteSubtakah (item) {
			this.$confirm({
				auth: false,
				message: "Anda yakin ingin menghapus data ini?",
				button: {
					no: 'Tidak',
					yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'takah/destroy',
							formInput: item
						}).then( res => {
							console.log("Result: ", res)
							this.resetList()
							this.resetSubtakahList()
							setTimeout( e => {
								this.$toasted.success('Sukses menghapus data!').goAway(3000)
							}, 1500)
						}).catch( err => {
							let errorMsg = "Gagal menghapus data!"
							if (err.response.status == 400) errorMsg = "Akses terbatas untuk aksi yang dipilih!"
							this.$toasted.error(errorMsg).goAway(3000)
						})
					}
				}
			})
		},
		makeHistory () {
			this.getSingleData(this.takahId)
				.then( res => {
					this.history.pages.push(res.single)
				})
		},
		toDocList (takahProp) {
			let routePrefix = this.$route.path
			this.history.path.push(routePrefix)
			var newRoute = routePrefix.replace(this.takahId, takahProp.takahId)
			this.$router
				.push(newRoute)
				.then( _ => {
					this.history.position += 1
					this.resetList()
					this.resetSubtakahList()
					this.getCategoryOptions()
					this.getReceiverOptions()
				})
		},
		goBack () {
			let goPosition = this.history.position-1
			if(goPosition < 0){
				this.$router.push('/takah')
			}
			else{
				this.$router
					.push(this.history.path[goPosition])
					.then( _ => {
						this.history.position -= 1
						this.resetList()
						this.resetSubtakahList()
						this.getCategoryOptions()
						this.getReceiverOptions()
					})
			}
		},

		closePopupModal() {
			this.closeSubtakahForm()
		},
		
		combineNoAndName(item) {
			if(item.takahNo == null || item.takahNo == '') {
				return item.takah
			} else {
				return item.takahNo + " - " + item.takah 
			}
		}
	}
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.text-folder {
    color: rgb(240, 196, 85);
}
span.vue-fontawesome > span.v-time-ago__text{
	font-size: 80%!important;
	margin-right: 10px;
}
</style>