<template>
    <CModal
        :show.sync="popupModalProp.isShow"
        :closeOnBackdrop="false"
        class="modal-fullscreen">
        <!-- Header -->
		<template #header>
			<h5 class="modal-title">{{ popupModalProp.modalTitle }}</h5>
            <CButtonClose @click="closePopupModal" class="text-gray"/>
		</template>
        <!-- Body -->
        <div class="mb-10">
            <advance-search
                ref="advanceSearch"
                :category-options-prop="takahForm.categoryOptions"
                @apply-advance-filter="filterDocument"/>
        </div>
        <div v-if="loader === true" class="loader content-center p-2">
            <v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
        </div>
        <div v-else class="body-container p-1">
            <CListGroup v-if="docItems.length > 0">
                <CListGroupItem
                    v-for="(item, index) in docItems"
                    :key="index"
                    class="flex-column mb-3 p-0"
                    style="border-width:0">
                    <div class="d-flex w-100">
                        <div class="flex-grow-0">
                            <div class="p-2 mt-3">
                                <CInputCheckbox
                                    :inline="true"
                                    :checked="takahForm.collection.includes(item.doc_id)"
                                    @update:checked="updateCollectionInput(item.doc_id)"/>                                            
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <CCard class="mb-0" @click="() => {}">
                                <CCardBody class="px-5 py-2">
                                    <div class="d-flex w-100">
                                        <div class="mb-1 flex-grow-1">
                                            <div class="font-weight-bold">{{ item.title }}</div>
                                            <div lass="help-block small">{{ item.doc_no }}</div>
                                        </div>
                                        <div class="flex-grow-0 text-right">
                                            <CBadge color="info" shape="pill">{{ item.time }}</CBadge>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </div>
                    </div>
                </CListGroupItem>
            </CListGroup>
            <div class="p-5" v-else>
                <p class="text-center">Maaf, Surat Tidak Ditemukan.</p>
            </div>
        </div>
        <!-- Footer -->
        <template #footer>
			<CButton @click="closePopupModal" class="btn-sm">Batal</CButton>
            <CButton @click="saveTakahFiles" color="success" class="btn-sm text-white" :disabled="takahForm.submitBtn.disabled">
                <font-awesome-icon v-if="takahForm.submitBtn.loader" icon="spinner" spin/> {{ takahForm.submitBtn.text }}
            </CButton>
		</template>
	</CModal>
</template>

<script>
import { mapState } from 'vuex'
import AdvanceSearch from '@/components/document/AdvanceSearch.vue'
export default {
    props: {
        popupModalProp: {
            type: Object,
            required: true
        },
        takahIdProp: {
            type: Number,
            required: true
        }
    },
    components: {
		'advance-search': AdvanceSearch
    },
    data () {
		const takahForm = {
            takahId: null,
            filter: {
                title: "",
				docNo: "",
				dateStart: "",
				dateFinish: "",
				category: "",
				type: "Semua",
				trait: "Semua",
				badge: "Semua",
				level: "Semua",
				isPublish: false,
				isFavorite: false,
                isArchieve: true,
				orderBy: "created_at",
				orderType: "desc"
            },
            categoryOptions: [],
            collection: [],
            submitBtn: {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
        }

        return {
			loader: true,
			docItems: [],
            takahForm
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserPosition: 'position',
            loggedUserPositionId: 'position_id',
		}),
    },
    mounted () {
        this.loader = true
        this.getCategoryOptions()
        this.getDocList()
    },
    methods: {
        getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
                    }
					items.push(rowData)
				})
				
                this.takahForm.categoryOptions = items
			})
		},
        getDocList() {
            this.takahForm.filter.isArchieve = true
			this.$store.dispatch(
				{
					type: 'document/getDocument',
					pagination: {
						limit: 20,
						offset: 0
					},
					filter: this.takahForm.filter
				}
			).then( res => {
				if(res && res.result.length > 0){
					res.result.forEach( item => {
                        item.time = this.$moment(item.created_at).format("HH:mm, DD MMM YYYY")
                        if (item.takah == null) this.docItems.push(item)
					})
                }
                
				this.loader = false
			})
        },
        filterDocument (params) {
			this.loader = true
            this.docItems = []
            this.takahForm.collection = []

            let advanceSearchComp = this.$refs.advanceSearch
			advanceSearchComp.loading = true
			this.takahForm.filter = params.filters

            this.getDocList()
            advanceSearchComp.refreshFilterBadges()
            advanceSearchComp.loading = false
		},
        updateCollectionInput (item) {
			if(this.takahForm.collection.includes(item)){
				let iDx = this.takahForm.collection.indexOf(item)
				this.takahForm.collection.splice(iDx, 1)
			}else{
				this.takahForm.collection.push(item)
            }

            if (this.takahForm.collection.length > 0) this.takahForm.submitBtn.disabled = false
            else this.takahForm.submitBtn.disabled = true
		},

        saveTakahFiles () {
            if (this.takahIdProp && this.takahForm.takahId == null) this.takahForm.takahId = this.takahIdProp
            this.$confirm({
				auth: false,
                message: "Tambah data terpilih?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
                        this.takahForm.submitBtn = {
                            loader: true,
                            text: 'Memproses...',
                            disabled: true
                        }

						this.$store.dispatch({
							type: 'takah/store_files',
                            formInput: {
                                docId: this.takahForm.collection,
                                takahId: this.takahForm.takahId
                            }
						}).then( res => {
							console.log("Result: ", res)
                            this.$toasted.success("Sukses menambah data!").goAway(3000)
                            this.closePopupModal(true)
						}).catch( err => {
                            console.log("Error: ", err.response)
							let errorMsg = "Gagal menambah data!"
							this.takahForm.submitBtn = {
                                loader: false,
                                text: 'Simpan',
                                disabled: true
                            }
                            this.$toasted.error(errorMsg).goAway(3000)
						})
					}
				}
			})
        },
        closePopupModal (reloadState = false) {
            this.takahForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
            this.$emit('close-popup-modal', reloadState)
        }
    }
}
</script>

<style scoped lang="scss">
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>